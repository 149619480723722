import React, { useState } from "react";
import Footer from "./Footer";
import lobby1 from "../Assets/Lobby1.jpeg";
import lobby2 from "../Assets/Lobby2.jpeg";
import lobby3 from "../Assets/Lobby3.jpeg";
import lobby4 from "../Assets/Lobby4.jpeg";
import { PRICE } from "../prices";
export default function About() {
  const selectedImg = [lobby1, lobby2, lobby3, lobby4];
  const [lobby, setLobby] = useState(0);

  return (
    <>
      <div className="dineInn-container">
        <h1>Happy Yummy Buffet</h1>
        <div className="layout">
          <div className="carousel-container">
            <div className="carousel-image" style={{ backgroundImage: `url(${selectedImg[lobby]})` }}></div>
            <div className="carousel-btns">
              <button
                onClick={() => {
                  setLobby(0);
                }}
                className="img-btn"
              >
                <img
                  alt="Lobby Images"
                  style={{ border: lobby === 0 ? "3px solid #fde7e9" : "none" }}
                  src={lobby1}
                />
              </button>
              <button
                onClick={() => {
                  setLobby(1);
                }}
                className="img-btn"
              >
                <img
                  alt="Lobby Images"
                  style={{ border: lobby === 1 ? "3px solid #fde7e9" : "none" }}
                  src={lobby2}
                />
              </button>
              <button
                onClick={() => {
                  setLobby(2);
                }}
                className="img-btn"
              >
                <img
                  alt="Lobby Images"
                  style={{ border: lobby === 2 ? "3px solid #fde7e9" : "none" }}
                  src={lobby3}
                />
              </button>
              <button
                onClick={() => {
                  setLobby(3);
                }}
                className="img-btn"
              >
                <img
                  alt="Lobby Images"
                  style={{ border: lobby === 3 ? "3px solid #fde7e9" : "none" }}
                  src={lobby4}
                />
              </button>
            </div>
          </div>
          <div className="buffet-prices">
            <div className="dinner">
              <p className="meal-name">Dinner</p>
              <p className="meal-price">{PRICE.buffet}</p>
            </div>
            <div className="dinner-hours">
              <p className="meal-name">Buffet Hours</p>
              <div className="dinner-content">
                <p className="meal-price">Friday:</p>
                <p className="meal-price">4:00PM - 7:00PM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
