import { PRICE } from "../prices";

export const appetizers = [
  {
    name: "Delicious Egg Roll",
    price: PRICE.delicious_egg_roll,
    description: "",
  },
  {
    name: "Delicious Spring Roll",
    price: PRICE.delicious_spring_roll,
    description: "",
  },
  {
    name: "Onion Rings",
    price: PRICE.onion_rings,
    description: "",
  },
  {
    name: "French Fries",
    price: PRICE.french_fries,
    description: "",
  },
  {
    name: "Fish and Chips",
    price: PRICE.fish_and_chips,
    description: "",
  },
  {
    name: "B.B.Q Pork Slices",
    price: PRICE.bbq_pork_slices,
    description: "",
  },
  {
    name: "Chicken Wings (crispy)",
    price: PRICE.chicken_wings_crispy,
    description: "",
  },
  {
    name: "BBQ Chicken on the stick",
    price: PRICE.bbq_chicken_on_the_stick,
    description: "",
  },
  {
    name: "Bo Bo Tray For Two",
    price: PRICE.bo_bo_tray_for_two,
    description:
      "(2 Egg Rolls, Honey Garlic Spare Ribs, BBQ Chicken Stick, Crispy Chicken Wings, Fried Wontons and Chicken Balls)",
  },
];

export const soups = [
  {
    name: "Chicken, Beef or Pork Noodle Soup",
    price: PRICE.chicken_beef_or_pork_noodle_soup,
    description: "",
  },
  {
    name: "Hot and Sour Soup",
    price: PRICE.hot_and_sour_soup,
    description: "",
  },
  {
    name: "Wonton Soup with BBQ pork",
    price: PRICE.wonton_soup_with_bbq_pork,
    description: "",
  },
  {
    name: "Egg Drop Soup",
    price: PRICE.egg_drop_soup,
    description: "",
  },
  {
    name: "Consomme Soup",
    price: PRICE.consomme_soup,
    description: "",
  },
];

export const sweetSour = [
  {
    name: "Sweet and Sour Chicken Balls",
    price: PRICE.sweet_and_sour_chicken_balls,
    description: "",
  },
  {
    name: "Sweet and Sour Pork",
    price: PRICE.sweet_and_sour_pork,
    description: "",
  },
  {
    name: "Sweet and Sour Breaded Shrimps",
    price: PRICE.sweet_and_sour_breaded_shrimps,
    description: "",
  },
  {
    name: "Sweet and Sour Wonton",
    price: PRICE.sweet_and_sour_wonton,
    description: "",
  },
  {
    name: "Sweet and Sour Cream Cheese Wonton",
    price: PRICE.sweet_and_sour_cream_cheese_wonton,
    description: "",
  },
];

export const chopSuey = [
  {
    name: "Chicken Chop Suey",
    price: PRICE.chicken_chop_suey,
    description: "",
  },
  {
    name: "Beef Chop Suey",
    price: PRICE.beef_chop_suey,
    description: "",
  },
  {
    name: "BBQ Pork Chop Suey",
    price: PRICE.bbq_pork_chop_suey,
    description: "",
  },
  {
    name: "Mushroom Chop Suey",
    price: PRICE.mushroom_chop_suey,
    description: "",
  },
  {
    name: "Vegetable Chop Suey",
    price: PRICE.vegetable_chop_suey,
    description: "",
  },
  {
    name: "Shrimp and Mushroom Chop Suey",
    price: PRICE.shrimp_and_mushroom_chop_suey,
    description: "",
  },
  {
    name: "House Special Chop Suey",
    price: PRICE.house_special_chop_suey,
    description: "",
  },
];

export const honeyGarlic = [
  {
    name: "Honey Garlic Chicken Wings",
    price: PRICE.honey_garlic_chicken_wings,
    description: "",
  },
  {
    name: "Honey Garlic Spare Ribs",
    price: PRICE.honey_garlic_spare_ribs,
    description: "",
  },
  {
    name: "Honey Garlic Shrimps",
    price: PRICE.honey_garlic_shrimps,
    description: "",
  },
  {
    name: "Honey Garlic Chicken Ball",
    price: PRICE.honey_garlic_chicken_ball,
    description: "",
  },
];
export const friedRice = [
  {
    name: "Chicken Fried Rice",
    price: PRICE.chicken_fried_rice,
    description: "",
  },
  {
    name: "Beef Fried Rice",
    price: PRICE.beef_fried_rice,
    description: "",
  },
  {
    name: "Pork Fried Rice",
    price: PRICE.pork_fried_rice,
    description: "",
  },
  {
    name: "Mushroom Fried Rice",
    price: PRICE.mushroom_fried_rice,
    description: "",
  },
  {
    name: "Vegetable Fried Rice",
    price: PRICE.vegetable_fried_rice,
    description: "",
  },
  {
    name: "Shrimp Fried Rice",
    price: PRICE.shrimp_fried_rice,
    description: "",
  },
  {
    name: "House Special Fried Rice",
    price: PRICE.house_special_fried_rice,
    description: "",
  },
  {
    name: "Steamed Rice",
    price: PRICE.steamed_rice,
    description: "",
  },
];

export const vegetableAlmond = [
  {
    name: "Stir Fried Mixed Vegetables Almond",
    price: PRICE.stir_fried_mixed_vegetables_almond,
    description: "",
  },
  {
    name: "Almond Guy Ding (Chicken)",
    price: PRICE.almond_guy_ding_chicken,
    description: "",
  },
  {
    name: "Almond Beef Ding",
    price: PRICE.almond_beef_ding,
    description: "",
  },
  {
    name: "Almond B.B.Q Pork Ding",
    price: PRICE.almond_bbq_pork_ding,
    description: "",
  },
  {
    name: "Almond Shrimp Ding",
    price: PRICE.almond_shrimp_ding,
    description: "",
  },
];

export const loMein = [
  {
    name: "Plain Lo-Mein",
    price: PRICE.plain_lo_mein,
    description: "",
  },
  {
    name: "Mixed Vegetable Lo-Mein",
    price: PRICE.mixed_vegetable_lo_mein,
    description: "",
  },
  {
    name: "Chicken Lo-Mein (with Mixed Vegetables)",
    price: PRICE.chicken_lo_mein_with_mixed_vegetables,
    description: "",
  },
  {
    name: "Pork Lo-Mein (with Mixed Vegetables)",
    price: PRICE.pork_lo_mein_with_mixed_vegetables,
    description: "",
  },
  {
    name: "Beef Lo-Mein (with Mixed Vegetables)",
    price: PRICE.beef_lo_mein_with_mixed_vegetables,
    description: "",
  },
  {
    name: "Shrimp Lo-Mein (with Mixed Vegetables)",
    price: PRICE.shrimp_lo_mein_with_mixed_vegetables,
    description: "",
  },
  {
    name: "Shanghai Chow Mein (Chicken or Beef)",
    price: PRICE.shanghai_chow_mein_chicken_or_beef,
    description: "",
  },
  {
    name: "Singapore Noodle",
    price: PRICE.singapore_noodle,
    description: "",
  },
];

export const eggFuYoung = [
  {
    name: "Chicken Egg Fu Young",
    price: PRICE.chicken_egg_fu_young,
    description: "",
  },
  {
    name: "B.B.Q Pork Egg Fu Young",
    price: PRICE.bbq_pork_egg_fu_young,
    description: "",
  },
  {
    name: "Beef Egg Fu Young",
    price: PRICE.beef_egg_fu_young,
    description: "",
  },
  {
    name: "Mushroom Egg Fu Young",
    price: PRICE.mushroom_egg_fu_young,
    description: "",
  },
  {
    name: "Shrimp Egg Fu Young",
    price: PRICE.shrimp_egg_fu_young,
    description: "",
  },
];

export const curry = [
  {
    name: "Curry Chicken with Vegetables",
    price: PRICE.curry_chicken_with_vegetables,
    description: "",
  },
  {
    name: "Curry Beef with Vegetables",
    price: PRICE.curry_beef_with_vegetables,
    description: "",
  },
  {
    name: "Curry Shrimp with Vegetables",
    price: PRICE.curry_shrimp_with_vegetables,
    description: "",
  },
];

export const szechaun = [
  {
    name: "Kung Po (Chicken or Beef)",
    price: PRICE.kung_po_chicken_or_beef,
    description: "",
  },
  {
    name: "Kung Pao Shrimps",
    price: PRICE.kung_pao_shrimps,
    description: "",
  },
  {
    name: "Szechuan Chicken Or Beef",
    price: PRICE.szechuan_chicken_or_beef,
    description: "",
  },
];

export const beefDishes = [
  {
    name: "Beef or Chicken Mixed Vegetables",
    price: PRICE.beef_or_chicken_mixed_vegetables,
    description: "",
  },
  {
    name: "Beef or Chicken with Mushroom",
    price: PRICE.beef_or_chicken_with_mushroom,
    description: "",
  },
  {
    name: "Beef or Chicken with Green Pepper",
    price: PRICE.beef_or_chicken_with_green_pepper,
    description: "",
  },
  {
    name: "Beef or Chicken with Broccoli",
    price: PRICE.beef_or_chicken_with_broccoli,
    description: "",
  },
  {
    name: "Beef or Chicken with Black Bean Sauce",
    price: PRICE.beef_or_chicken_with_black_bean_sauce,
    description: "",
  },
  {
    name: "Beef or Chicken with Satay Sauce",
    price: PRICE.beef_or_chicken_with_satay_sauce,
    description: "",
  },
  {
    name: "Black Pepper Sauce with Beef or Chicken",
    price: PRICE.black_pepper_sauce_with_beef_or_chicken,
    description: "",
  },
];

export const seafood = [
  {
    name: "Shrimp Lobster Sauce",
    price: PRICE.shrimp_lobster_sauce,
    description: "(Stir fried shrimp with minced porkin special sauce)",
  },
  {
    name: "Shrimp with Broccoli",
    price: PRICE.shrimp_with_broccoli,
    description:
      "(Scallops, shrimp and mixed Chinese vegetables with our own sauce)",
  },
  {
    name: "Shrimp with Green Pepper",
    price: PRICE.shrimp_with_green_pepper,
    description: "Breaded scallops cooked with Chinese Vegetables)",
  },
];

export const chefSuggestions = [
  {
    name: "Cantonese Shrimp",
    price: PRICE.cantonese_shrimp,
    description: "(Fresh Shrump stir fried with special sauce)",
  },
  {
    name: "Seafood Lover",
    price: PRICE.seafood_lover,
    description: "(Stir Fried Crab Meat, Scallops, Shrimps with Mushroom)",
  },
  {
    name: "Cantonese Chow Mein",
    price: PRICE.cantonese_chow_mein,
    description:
      "(Fresh Egg Noodle Pan Fried to Golden Brown, Prepared with B.B.Q Pork, Beef, Shrimp, Chicken, Mushroom, and Mix Fresh Vegetables)",
  },
  {
    name: "Cantonese Lo Mein",
    price: PRICE.cantonese_lo_mein,
    description: "",
  },
  {
    name: "Chow Har Kew",
    price: PRICE.chow_har_kew,
    description: "(Fresh Large Shrimp with Mix Vegetables)",
  },
  {
    name: "Stir Fried Mixed Vegetables",
    price: PRICE.stir_fried_mixed_vegetables,
    description: "",
  },
  {
    name: "Tai Dop Woey",
    price: PRICE.tai_dop_woey,
    description:
      "(Stir Fried Chicken, Shrimp, B.B.Q Pork and Beef with Mix Vegetables)",
  },
  {
    name: "Butterfly Shrimp",
    price: PRICE.butterfly_shrimp,
    description:
      "(Selected Jumbo Shrimp, Wrapped in Bacon and Egg, Pan Fried to Golden Brown)",
  },
  {
    name: "Moo Goo Guy Pan",
    price: PRICE.moo_goo_guy_pan,
    description: "",
  },
  {
    name: "General Tao chicken",
    price: PRICE.general_tao_chicken,
    description: "",
  },
  {
    name: "Orange Chicken",
    price: PRICE.orange_chicken,
    description: "",
  },
  {
    name: "Lemon Chicken",
    price: PRICE.lemon_chicken,
    description: "",
  },
  {
    name: "Sesame Chicken",
    price: PRICE.sesame_chicken,
    description: "",
  },
  {
    name: "Chicken Soo Guy",
    price: PRICE.chicken_soo_guy,
    description: "",
  },
];

export const combo1 = [
  "Egg Roll",
  "Sweet and Sour Chicken Balls",
  "Chicken Fried Rice",
  "Fortune Cookie",
];
export const combo2 = [
  "Egg Roll",
  "Chicken Soo Guy",
  "Chicken Fried Rice",
  "Fortune Cookie",
];
export const combo3 = [
  "Egg Roll",
  "Diced Chicken Vegetables and Almond",
  "Chicken Fried Rice",
  "Fortune Cookie",
];
export const combo4 = [
  "Egg Roll",
  "Lemon Chicken",
  "Chicken Fried Rice",
  "Fortune Cookie",
];
export const combo5 = [
  "Egg Roll",
  "Sweet and Sour Breaded Shrimp",
  "Chicken Fried Rice",
  "Fortune Cookie",
];
export const combo6 = [
  "Egg Roll",
  "Chicken Chop Suey",
  "Chicken Fried Rice",
  "Fortune Cookie",
];

export const dinnerOneA = [
  "Egg Roll",
  "Sweet and Sour Chicken Balls",
  "Diced Chicken with Vegetables Almonds",
  "Chicken Fried Rice",
  "Fortune Cookies",
];
export const dinnerOneB = [
  "Egg Roll",
  "Sweet and Sour Spare Ribs",
  "Chicken Chop Suey",
  "Chicken Fried Rice",
  "Fortune Cookies",
];
export const dinnerTwo = [
  "2 Egg Rolls",
  "Sweet & Sour Chicken Balls",
  "Chicken Chop Suey",
  "Chicken Fried Rice",
  "Fortune Cookies",
];

export const dinnerThree = [
  "3 Egg Rolls",
  "Sweet & Sour Chicken Balls",
  "Chicken w/ Mixed Vegetables Almond",
  "Lemon Chicken",
  "Chicken Fried Rice",
  "Fortune Cookies",
];

export const dinnerFour = [
  "4 Egg Rolls",
  "Chicken Chop Suey",
  "Beef Mixed Vegetables",
  "Breaded Shrimp",
  "Sweet & Sour Chicken Balls",
  "Chicken Fried Rice",
  "Fortune Cookies",
];

export const dinnerFive = [
  "5 Egg Rolls",
  "Sweet & Sour Chicken Balls",
  "Chicken Soo Guy with Gravy",
  "Chicken Chop Suey",
  "Breaded Shrimps",
  "Beef with Mix Vegetables",
  "Chicken Fried Rice",
  "Fortune Cookies",
];

export const dinnerSix = [
  "6 Egg Rolls",
  "Tai Dop Woey",
  "Honey Garlic Spare Ribs",
  "Chicken Soo Guy with Gravy",
  "Breaded Shrimps",
  "Sweet and Sour Chicken Balls",
  "Chicken Chop Suey",
  "House Special Fried Rice",
  "Fortune Cookies",
];
