export const PRICE = {
  //#region Chef's Suggestions
  cantonese_shrimp: "$20.99",
  seafood_lover: "$21.99",
  cantonese_chow_mein: "$17.99",
  cantonese_lo_mein: "$17.99",
  chow_har_kew: "$16.99",
  stir_fried_mixed_vegetables: "$13.99",
  tai_dop_woey: "$16.99",
  butterfly_shrimp: "$19.99",
  moo_goo_guy_pan: "$15.99",
  general_tao_chicken: "$14.99",
  orange_chicken: "$14.99",
  lemon_chicken: "$14.99",
  sesame_chicken: "$14.99",
  chicken_soo_guy: "$15.99",

  //#region Appetizers
  delicious_egg_roll: "$2.50",
  delicious_spring_roll: "$2.80",
  onion_rings: "$6.50",
  french_fries: "$6.50",
  fish_and_chips: "$15.50",
  bbq_pork_slices: "$16.99",
  chicken_wings_crispy: "$14.99",
  bbq_chicken_on_the_stick: "$15.99",
  bo_bo_tray_for_two: "$28.99",

  //#region Soups
  chicken_beef_or_pork_noodle_soup: "$6.00",
  hot_and_sour_soup: "$7.00",
  wonton_soup_with_bbq_pork: "$6.00",
  egg_drop_soup: "$6.00",
  consomme_soup: "$4.00",

  //#region Sweet & Sour
  sweet_and_sour_chicken_balls: "$14.99",
  sweet_and_sour_pork: "$14.99",
  sweet_and_sour_breaded_shrimps: "$14.99",
  sweet_and_sour_wonton: "$8.99",
  sweet_and_sour_cream_cheese_wonton: "$12.99",

  //#region Fried Rice
  chicken_fried_rice: "$12.50",
  beef_fried_rice: "$12.50",
  pork_fried_rice: "$12.50",
  mushroom_fried_rice: "$12.50",
  vegetable_fried_rice: "$12.50",
  shrimp_fried_rice: "$14.99",
  house_special_fried_rice: "$14.99",
  steamed_rice: "$4.99",

  //region Diced Vegetable Almond
  stir_fried_mixed_vegetables_almond: "$14.99",
  almond_guy_ding_chicken: "$14.99",
  almond_beef_ding: "$14.99",
  almond_bbq_pork_ding: "$14.99",
  almond_shrimp_ding: "$16.99",

  //#region Chop Suey
  chicken_chop_suey: "$12.99",
  beef_chop_suey: "$12.99",
  bbq_pork_chop_suey: "$12.99",
  mushroom_chop_suey: "$12.99",
  vegetable_chop_suey: "$12.99",
  shrimp_and_mushroom_chop_suey: "$15.99",
  house_special_chop_suey: "$15.99",

  //#region Honey Garlic Dishes
  honey_garlic_chicken_wings: "$15.99",
  honey_garlic_spare_ribs: "$17.99",
  honey_garlic_shrimps: "$15.99",
  honey_garlic_chicken_ball: "$15.99",

  //#region Lo-Mein
  plain_lo_mein: "$14.99",
  mixed_vegetable_lo_mein: "$14.99",
  chicken_lo_mein_with_mixed_vegetables: "$15.99",
  pork_lo_mein_with_mixed_vegetables: "$15.99",
  beef_lo_mein_with_mixed_vegetables: "$15.99",
  shrimp_lo_mein_with_mixed_vegetables: "$17.99",
  shanghai_chow_mein_chicken_or_beef: "$16.99",
  singapore_noodle: "$17.99",

  //#region Egg Fu Young
  chicken_egg_fu_young: "$14.99",
  bbq_pork_egg_fu_young: "$14.99",
  beef_egg_fu_young: "$14.99",
  mushroom_egg_fu_young: "$14.99",
  shrimp_egg_fu_young: "$16.99",

  //#Curry Dishes
  curry_chicken_with_vegetables: "$15.99",
  curry_beef_with_vegetables: "$15.99",
  curry_shrimp_with_vegetables: "$16.99",

  //#region Szechuan Style
  kung_po_chicken_or_beef: "$15.99",
  kung_pao_shrimps: "$16.99",
  szechuan_chicken_or_beef: "$15.99",

  //#region Beef or Chicken Dishes
  beef_or_chicken_mixed_vegetables: "$14.99",
  beef_or_chicken_with_mushroom: "$15.99",
  beef_or_chicken_with_green_pepper: "$14.99",
  beef_or_chicken_with_broccoli: "$14.99",
  beef_or_chicken_with_black_bean_sauce: "$14.99",
  beef_or_chicken_with_satay_sauce: "$14.99",
  black_pepper_sauce_with_beef_or_chicken: "$16.99",

  //#region Seafood Dishes
  shrimp_lobster_sauce: "$18.99",
  shrimp_with_broccoli: "$16.99",
  shrimp_with_green_pepper: "$16.99",

  // #region Dinner for One
  dinner_for_one: "$16.99",

  // #region Combo
  combo: "$13.99",

  // #region Family Dinners
  dinner_for_two: "$32.99",
  dinner_for_three: "$49.99",
  dinner_for_four: "$65.99",
  dinner_for_five: "$82.99",
  dinner_for_six: "$99.99",

  // #region Buffet
  buffet: "$22.99"
};
