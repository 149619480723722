import React from "react";
import { AiOutlinePhone } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { BiTimeFive } from "react-icons/bi";
function Footer() {
  return (
    <div id="footer" className="footer-container">
      <div className="footer-top">
        <div className="contact">
          <p className="footer-title">Contact Us:</p>
          <div className="contact-icon">
            <AiOutlinePhone />
          </div>
          <a href="tel:7058692326">705-869-2326</a>
        </div>
        <div className="location">
          <p className="footer-title">Location:</p>
          <div className="location-icon">
            <GoLocation />
          </div>
          <p>481 Centre Street</p>
          <p>Espanola (Highway 6)</p>
          <p>ON P5E 1T5</p>
        </div>
        <div className="hours">
          <p className="footer-title">Business Hours:</p>
          <div className="hours-icon">
            <BiTimeFive />
          </div>
          <div className="hours1">
            <p>Monday (3:00PM - 8:00PM)</p>
          </div>
          <div className="hours2">
            <p>Tuesday-Saturday (11:30AM - 8:00PM)</p>
          </div>
          <div className="hours3">
            <p>Sunday (11:30AM-7:30PM)</p>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="credits-1">
          <p className="credit-title">Developed By:</p>
          <p>yu.kevin2002@gmail.com</p>
        </div>
        <div className="credits-2">
          <p className="credit-title">Icon Credits</p>
          <div className="credit">
            Icons made by{" "}
            <a href="https://www.flaticon.com/authors/mangsaabguru" title="mangsaabguru">
              mangsaabguru
            </a>{" "}
            from{" "}
            <a href="https://www.flaticon.com/" title="Flaticon">
              www.flaticon.com
            </a>
          </div>
          <div className="credit">
            Icons made by{" "}
            <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
              Freepik
            </a>{" "}
            from{" "}
            <a href="https://www.flaticon.com/" title="Flaticon">
              www.flaticon.com
            </a>
          </div>
          <div className="credit">
            Icons made by{" "}
            <a href="https://www.flaticon.com/authors/eucalyp" title="Eucalyp">
              Eucalyp
            </a>{" "}
            from{" "}
            <a href="https://www.flaticon.com/" title="Flaticon">
              www.flaticon.com
            </a>
          </div>
          <div className="credit">
            Icons made by{" "}
            <a href="https://www.flaticon.com/authors/iconixar" title="iconixar">
              iconixar
            </a>{" "}
            from{" "}
            <a href="https://www.flaticon.com/" title="Flaticon">
              {" "}
              www.flaticon.com
            </a>
          </div>
          <p className="copyright">&#169; All Rights Reserved. Happy Yummy Restaurant.</p>
        </div>
        <div />
      </div>
    </div>
  );
}
export default Footer;
